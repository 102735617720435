<template>
  <div
    @click="
      () => {
        $router.push({
          name: 'ilanlar.detay',
          query: { adId: detail.ID },
        });
      }
    "
    class="row cursor-pointer"
    :class="{ 'w-100': advertShowType === 'dashboard' }"
  >
    <div class="mb-3">
      <div class="ad" :style="detail.IsVisited == true ? 'background-color: #A6B3C5' : null">
        <div class="adDetails">
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <span> #{{ detail.AdvertNo }} </span>
            </div>
            <div class="text-center hasOffer" v-if="detail.BidCount > 0">
              <span @click="advertsDialog = true" style="font-size: 12px">
                {{ detail.BidCount }}
                {{ user.IsCarrier ? "Teklif Verildi" : "Teklif" }}
              </span>
            </div>
            <div class="text-center passive" v-if="detail.Status === 6">
              <span @click="advertsDialog = true" style="font-size: 12px">
                Pasif
              </span>
            </div>
          </div>
          <span>
            {{ detail.ProductType }}
          </span>
          <span>
            <div class="d-flex">
              <img src="../../assets/icons/nkicon/destination.svg" alt="" style="margin-right: 10px; width: 20px" />
              <div>
                <div class="d-inline">
                  <!-- <span
                    ><b style="color: #ffffff">Yükleme:</b>
                    {{ detail.Warehouse.DistrictTitle }}
                    | {{ detail.Warehouse.CityTitle }}
                  </span> -->
                   <span
                    ><b style="color: #ffffff">Yükleme:</b>
                    {{ detail.WarehouseDistrict }}
                    | {{ detail.WarehouseCity }}
                  </span>
                </div>
                &nbsp;
                <!-- <div class="d-inline">
                  <span
                    ><b style="color: #ffffff">İndirme:</b>
                    {{ detail.DeliveryCompany.DistrictTitle }}
                    | {{ detail.DeliveryCompany.CityTitle }}</span
                  >
                </div> -->
                <br>
                <div class="d-inline">
                  <span
                    ><b style="color: #ffffff">İndirme:</b>
                    {{ detail.DeliveryCompanyDistrict }}
                    | {{ detail.DeliveryCompanyCity}}</span
                  >
                </div>
              </div>
            </div>
          </span>
          <span>
            <img src="../../assets/icons/nkicon/calendar.svg" alt="" style="margin-right: 10px; width: 20px" />{{
              $moment(detail ? detail.AdvertDate : null)
                .add(1, "days")
                .format("LL")
            }}
          </span>
        </div>

        <div class="d-flex justify-content-center align-items-center" style="margin-right: 20px">
          <img src="../../assets/icons/nkicon/adddetail.svg" alt="" style="cursor: pointer; margin-right: 30px; width: 60px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["detail", "advertShowType"],
  data() {
    return {
      advertsDialog: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["userState"];
    },
  },
};
</script>

<style>
.disabledAd {
  background-color: #d9d9d9;
  margin-left: 10px;
  border-radius: 4px;
  padding: 1px 20px;
}

.disabledAd span {
  color: #4d668a;
}

.ad {
  border-radius: 16px;
  background-color: #4d668a;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.adDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 20px;
}

.adDetails span {
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
}

.adDetails span:first-child {
  font-size: 22px;
  margin-left: 0px;
}

.adDetails span:nth-child(2) {
  font-size: 22px;
  margin-left: 0px;
}
.adDetails span:last-child {
  margin-bottom: 0;
}
.hasOffer {
  background-color: #82c0cc;
  margin-left: 10px;
  border-radius: 4px;
  padding: 1px 20px;
}
.passive {
  background-color: #6d6d6d;
  margin-left: 10px;
  border-radius: 4px;
  padding: 1px 20px;
}
@media (max-width: 380px) {
  .ad div:nth-child(2) img {
    margin-right: 0 !important;
  }
}
</style>
