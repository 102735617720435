<template>
  <div>
    <div class="row invoice-card-row">
      <div class="col-md-12 d-flex mb-3 offer-row">
        <!-- <div
          href="javascript:;"
          @click="offerDialog = true"
          v-if="user.AccountStatus === 1"
          class="
            border border-radius
            d-flex
            justify-content-between
            align-items-center
            p-3
            mb-2
            me-4
            bg-blue
            isConfirmed
          "
        >
          <span class="text-white me-3"> Üyeliğiniz onay bekliyor</span>
          <img src="../../assets/icons/nkicon/right-arrow-white.svg" alt="" />
        </div> -->
        <div
          href="javascript:;"
          @click="telConfirmDialog = true"
          v-if="user.PhoneConfirmation && !user.PhoneConfirmation.IsConfirmed"
          class="
            border border-radius
            d-flex
            justify-content-between
            align-items-center
            p-3
            mb-2
            me-4
            bg-blue
            isConfirmed
          "
        >
          <span class="text-white me-3"> Telefonunuz onay bekliyor</span>
          <img src="../../assets/icons/nkicon/right-arrow-white.svg" alt="" />
        </div>
      </div>

      <div class="col-xl-12">
        <h3>{{ user.IsCarrier ? "İlanlar" : "İlanlarım" }}</h3>
        <not-found-info :item="filteredNonDisplayedAds()" :title="'Görüntülemediğiniz ilan'" :emptyName="'advert'" />
        <el-carousel v-if="filteredNonDisplayedAds() && filteredNonDisplayedAds().length" :interval="5000" arrow="always">
          <el-carousel-item v-for="(item, index) in filteredNonDisplayedAds()" :key="index">
            <my-ad :key="index" :detail="item" :advertShowType="advertShowType" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="row col-xl-12">
        <h3>İstatistikler</h3>
        <div class="card-container col-xl-4">
          <div
            class="card invoice-card"
            :class="{
              'bg-pink': !user.IsCarrier,
              'bg-sup-rev': user.IsCarrier,
            }"
          >
            <div class="card-body d-flex">
              <div>
                <h3 class="text-white invoice-num"><strong>Toplam</strong> <br />{{ user.IsCarrier ? "Gelir" : "Gider" }}</h3>
                <span class="text-white fs-18">{{ dashboardStatistics.TotalIncome }}₺</span>
              </div>
            </div>
          </div>
        </div>

        <div class="card-container col-xl-4">
          <div
            class="card invoice-card"
            :class="{
              'bg-teal': !user.IsCarrier,
              'bg-sup-op': user.IsCarrier,
            }"
          >
            <div class="card-body d-flex">
              <div>
                <h3 class="text-white invoice-num"><strong>Toplam</strong> <br />İşlem</h3>
                <span class="text-white fs-18">{{ dashboardStatistics.TotalOperations }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="card-container col-xl-4">
          <div class="card bg-blue invoice-card">
            <div class="card-body d-flex">
              <div>
                <h3 class="text-white invoice-num"><strong>Bekleyen</strong> <br />İşlemler</h3>
                <span class="text-white fs-18">{{ dashboardStatistics.WaitingAdverts }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="apex-row">
      <div class="row">
        <div :class="{ 'col-xl-12': !user.IsCarrier, 'col-xl-9': user.IsCarrier }">
          <h3>{{ user.IsCarrier ? "Gelirler" : "Giderler" }}</h3>
          <not-found-info :item="revenuesSeries[0].data" :title="user.IsCarrier ? 'Gelirler' : 'Giderler'" :emptyName="'none'" />
          <div v-if="revenuesSeries[0].data" class="row">
            <div class="d-flex justify-content-end w-100">
              <div class="d-flex justify-content-between w-40" style="width: 250px">
                <button class="revenue-button" :class="{ active: activeRevenue === TimelineOpt.OneDay }" @click="setRevenues(TimelineOpt.OneDay)">
                  1G
                </button>
                <button class="revenue-button" :class="{ active: activeRevenue === TimelineOpt.OneWeek }" @click="setRevenues(TimelineOpt.OneWeek)">
                  1H
                </button>
                <button class="revenue-button" :class="{ active: activeRevenue === TimelineOpt.OneMonth }" @click="setRevenues(TimelineOpt.OneMonth)">
                  1AY
                </button>
                <button class="revenue-button" :class="{ active: activeRevenue === TimelineOpt.OneYear }" @click="setRevenues(TimelineOpt.OneYear)">
                  1Y
                </button>
                <button class="revenue-button" :class="{ active: activeRevenue === TimelineOpt.All }" @click="setRevenues(TimelineOpt.All)">
                  TÜMÜ
                </button>
              </div>
            </div>
            <div class="col-12 p-0">
              <apexchart type="line" height="300" :options="revenuesOpt" :series="revenuesSeries"></apexchart>
            </div>
          </div>
        </div>

        <div v-if="user.IsCarrier" class="row col-xl-3">
          <h3>Görüntülediğim İlanlar</h3>
          <not-found-info :item="filteredDisplayedAds()" :title="'İlan'" :emptyName="'advert'" />
          <el-carousel v-if="filteredDisplayedAds() && filteredDisplayedAds().length" :interval="5000" arrow="always">
            <el-carousel-item v-for="(item, index) in filteredDisplayedAds()" :key="index">
              <myAd :key="index" :detail="item" :advertShowType="advertShowType" />
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="col-xl-12 p-0">
          <div class="row h-100">
            <div class="col-md-12">
              <div
                class="
                  nakubox
                  bg-primary
                  border-radius
                  d-flex
                  justify-content-between
                  align-items-center
                  p-3
                "
              >
                <img src="../../assets/images/naku.svg" alt="" style="width: 100px" />
                <div class="total-naku">
                  <span class="fs-20 text-white">NAKU Hesabın:</span>
                  <span class="fs-20 text-white"> {{ user.Naku }} <strong>NA</strong>KU</span>
                </div>
                <button
                  @click="
                    () => {
                      $router.push({ path: '/magaza' });
                    }
                  "
                  class="bg-dark"
                >
                  <img src="../../assets/icons/nkicon/shop.svg" alt="" />
                </button>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="
                  bg-light
                  border-radius
                  card
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-column
                  text-center
                  p-2
                "
              >
                <p class="fs-20 text-white fw-normal">Planlanan Sevkiyatlar</p>
                <p class="fs-30 fw-bold text-white">{{ shipments.length }}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="
                  bg-light
                  border-radius
                  card
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                  flex-column
                  text-center
                  p-2
                "
              >
                <p class="fs-20 text-white fw-normal">Bugünkü Sevkiyatlar</p>
                <p class="fs-30 fw-bold text-white">
                  {{ dashboardStatistics.TodayTransportingAdverts }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 p-0">
        <div class="row h-100">
          <div class="col-md-12">
            <h3>Sevkiyatlarım</h3>
            <not-found-info :item="shipments" :title="'Sevkiyat'" :emptyName="'deliverys'" />

            <div v-if="shipments != null && shipments.length !== 0" class="shipment-card-wrapper">
              <div id="shipments-carousel" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <shipmentCard v-for="(item, index) in shipments" :key="index" :details="item" :shipmentShowType="shipmentShowType" :index="index" :activeShipment="activeShipment" />
                </div>
                <span @click="handleDecrement" class="carousel-control-prev-icon shipment-prev" aria-hidden="true"></span>
                <span class="sr-only">Önceki</span>

                <span @click="handleIncrement" class="carousel-control-next-icon shipment-next" aria-hidden="true"></span>
                <span class="sr-only">Sonraki</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 mt-3">
          <h3>Sevkiyat</h3>
          <div v-if="shipmentsSeries[0].data" class="row">
            <div class="d-flex justify-content-end w-100">
              <div class="d-flex justify-content-between w-40" style="width: 250px">
                <!-- <button
                  class="revenue-button"
                  :class="{
                    active: activeShipmentSeries === TimelineOpt.OneDay,
                  }"
                  @click="setShipments(TimelineOpt.OneDay)"
                >
                  1G
                </button> -->
                <button
                  class="revenue-button"
                  :class="{
                    active: activeShipmentSeries === TimelineOpt.OneWeek,
                  }"
                  @click="setShipments(TimelineOpt.OneWeek)"
                >
                  1H
                </button>
                <button
                  class="revenue-button"
                  :class="{
                    active: activeShipmentSeries === TimelineOpt.OneMonth,
                  }"
                  @click="setShipments(TimelineOpt.OneMonth)"
                >
                  1AY
                </button>
                <button
                  class="revenue-button"
                  :class="{
                    active: activeShipmentSeries === TimelineOpt.OneYear,
                  }"
                  @click="setShipments(TimelineOpt.OneYear)"
                >
                  1Y
                </button>
                <button class="revenue-button" :class="{ active: activeShipmentSeries === TimelineOpt.All }" @click="setShipments(TimelineOpt.All)">
                  TÜMÜ
                </button>
              </div>
            </div>
            <div class="col-12 p-0">
              <apexchart v-if="shipmentsSeries[0].data" type="bar" height="300" :options="shipmentsOpt" :series="shipmentsSeries"></apexchart>
            </div>
          </div>
        </div>

        <div v-if="user.IsCarrier" class="col-xl-12 mt-3">
          <h3>Puanlar</h3>
          <not-found-info :item="pointsSeries" :title="'Puan'" :emptyName="'rates'" />
          <div class="row">
            <div class="col-12 p-0">
              <apexchart v-if="pointsSeries" type="donut" height="300" :options="pointsOpt" :series="pointsSeries"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Telefon Numarası Onayı" :visible.sync="telConfirmDialog">
      <span class="text-white mb-3">Cep telefonunuza özel 4 haneli bir şifre gönderilecektir. Lütfen aktif olarak kullandığınız telefon numaranızı giriniz.</span>
      <el-input class="w-100 bg-light form-control py-0 mt-3" v-mask="['+90 (###) ### ## ##']" placeholder="+90 (xxx) xxx xx xx" v-model="PhoneNumber"></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" class="px-5 py-3" @click="telConfirm">Devam Et</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Bilgileriniz Kontrol Ediliyor!" :visible.sync="offerDialog">
      <p class="text-white">
        Üye olurken vermiş olduğunuz bilgileriniz sistem tarafından kontrol ediliyor.
      </p>
      <span class="text-blue">Maksimum 12 saat içerisinde bilgileriniz kontrol edilip, yanlış veya eksik bilgi olmaması durumunda üyeliğiniz onaylanacaktır.</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="bg-teal w-100" @click="offerDialog = false">Anladım</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" class="sm-modal" title="Telefon Numarası Onayı" :visible.sync="otpDialog">
      <span class="text-white mb-3">Cep telefonunuza özel 4 haneli bir şifre gönderilecektir. Lütfen aktif olarak kullandığınız telefon numaranızı giriniz.</span>

      <div class="otp-wrapper">
        <div class="input-row otp-row" @keydown="handleKeydown">
          <el-input ref="otp1" placeholder="..." v-model="otp1" :maxlength="1"></el-input>

          <el-input ref="otp2" placeholder="..." v-model="otp2" :maxlength="1"></el-input>
          <el-input ref="otp3" placeholder="..." v-model="otp3" :maxlength="1"></el-input>
          <el-input ref="otp4" placeholder="..." v-model="otp4" :maxlength="1"></el-input>
        </div>
      </div>
      <div>
        <el-button v-if="!loading" :loading="loading" :padding="false" class="bg-transparent" @click="telConfirm">
          <span>Tekrar Gönder</span>
        </el-button>

        <el-button :loading="loading" :padding="false" class="px-5 py-3" type="primary" @click="verifyOtp">Onayla</el-button>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :before-close="handleTrackLocation" title="Canlı Takip Ekranı" :visible.sync="trackMapDialog" :modal-append-to-body="false">
      <GmapMap :center="center" :zoom="14" style="width: 100%; height: 400px" ref="mapRef">
        <GmapMarker
          :position="{
            lat: marker.position.lat,
            lng: marker.position.lng,
          }"
        />
      </GmapMap>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleTrackLocation">Kapat</el-button>
      </span>
    </el-dialog>

    <!-- Odeme -->
  </div>
</template>

<script>
import { sortedMonths, sortedMonthsTr } from "../../constants/months";
import NotFoundInfo from "../../components/notFound/notFoundInfo.vue";
import shipmentCard from "../../components/shipmentCard/shipmentCard";
import MyAd from "../../components/myAd/myAd.vue";
import META_DATA from "../../constants/metaData";
import moment from "moment";
import { numOfDaysBetween } from "../../helpers/generalHelper";

export default {
  metaInfo() {
    return {
      title: META_DATA.DASHBOARD.Title,
    };
  },
  components: { shipmentCard, NotFoundInfo, MyAd },
  Button: "Dashbord",
  data() {
    return {
      telConfirmDialog: false,
      errorAnimation: false,
      passwordDialog: false,
      PhoneNumber: "",
      otpDialog: false,
      offerDialog: false,
      otp: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      telmodel: "",
      verifyLoading: false,
      advertShowType: "dashboard",
      shipmentShowType: "dashboard",
      revenuesInfo: {
        revenuesCategories: [],
        revenuesSeriesData: [],
      },
      shipmentsInfo: {
        shipmentsCategories: [],
        shipmentsSeriesData: [],
      },
      TimelineOpt: {
        OneDay: 0,
        OneWeek: 1,
        OneMonth: 2,
        OneYear: 3,
        All: 4,
      },
      activeRevenue: 4,
      activeShipmentSeries: 4,
      activeShipment: 0,
    };
  },
  computed: {
    trackMapDialog() {
      const { trackMapDialog } = this.$store.getters["nativeTrackMapDialog"];
      return trackMapDialog;
    },
    center() {
      const { center } = this.$store.getters["nativeTrackMapDialog"];
      return center;
    },
    marker() {
      const { marker } = this.$store.getters["nativeTrackMapDialog"];
      return marker;
    },
    nativeCMapDialog() {
      return this.$store.getters["nativeCMapDialog"];
    },
    nativeWHMapDialog() {
      return this.$store.getters["nativeWHMapDialog"];
    },
    dashboardStatistics: {
      get() {
        const data = this.$store.getters["dashboardStatistics"];
        data.advertsDetail = this.$store.getters["advertsDetail"];
        return data;
      },
    },
    user: {
      get() {
        return this.$store.getters["userState"];
      },
    },
    shipments: {
      get() {
        return this.$store.getters["shipments"];
      },
    },
    shipmentsOpt: {
      get() {
        return {
          colors: ["#E9501C"],
          labels: this.shipmentsInfo.shipmentsCategories,
          chart: {
            height: 300,
            type: "bar",
            foreColor: "whitesmoke",
          },
          plotOptions: {
            bar: {
              borderRadius: 16,
            },
          },
          yaxis: {
            show: false,
          },
          // dataLabels: {
          //   enabled: false,
          // },
          // stroke: {
          //   show: true,
          //   width: 2,
          //   colors: ["#E9501C"],
          // },
          // fill: {
          //   opacity: 1,
          // },
          // tooltip: {
          //   y: {
          //     formatter: function (val) {
          //       return "$ " + val + " thousands";
          //     },
          //   },
          // },
        };
      },
    },
    shipmentsSeries: {
      get() {
        return [
          {
            name: "Sevkiyat",
            data: this.shipmentsInfo.shipmentsSeriesData,
          },
        ];
      },
    },

    revenuesOpt: {
      get() {
        return {
          colors: ["#E9501C"],
          chart: {
            height: 300,
            type: "line",
            foreColor: "whitesmoke",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          candlestick: {
            colors: {
              upward: "#3C90EB",
              downward: "#DF7D46",
            },
          },
          defaultLocale: "tr",
          xaxis: {
            categories: this.revenuesInfo.revenuesCategories,
          },

          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        };
      },
    },
    revenuesSeries: {
      get() {
        return [
          {
            name: this.user.IsCarrier ? "Gelirler(₺)" : "Giderler(₺)",
            data: this.revenuesInfo.revenuesSeriesData,
          },
        ];
      },
    },
    pointsOpt: {
      get() {
        return {
          labels: ["O", "Ş", "M", "N", "M", "H", "T", "A", "E", "E", "K", "A"],
          chart: {
            type: "donut",
            height: 300,
            foreColor: "whitesmoke",
          },
          plotOptions: {
            pie: {
              donut: {
                size: "65%",
                labels: {
                  show: true,
                  total: {
                    show: true,
                    label: "",
                    formatter: () => "Puanlar",
                  },
                },
              },
            },
          },
        };
      },
    },
    pointsSeries: {
      get() {
        return this.getSortedLastOneYearPoints();
      },
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
  async created() {
    if (this.$store.state.User.token) {
      await this.$store.dispatch("getAdverts", { FilterMe: false });
      await this.$store.dispatch("getDashboardStatistics");
      await this.$store.dispatch("getTransferAdvertsShipment", {});
      this.setRevenues(this.TimelineOpt.All);
      this.setShipments(this.TimelineOpt.All);
    }
  },

  methods: {
    setRevenues(opt) {
      this.activeRevenue = opt;
      var dataSet = this.dashboardStatistics.PeriodicValues;
      switch (opt) {
        case this.TimelineOpt.OneDay:
          var filteredDataOneDay = this.filterAndSortChartData(dataSet, "day");
          this.revenuesInfo.revenuesCategories = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];
          this.revenuesInfo.revenuesSeriesData = Array(24)
            .fill(0)
            .map((val, idx) => {
              let price = 0;
              filteredDataOneDay.forEach((pValue) => {
                if (idx === pValue.ConfirmedDate.getHours()) price = pValue.ConfirmedPrice;
              });
              return price;
            });
          break;
        case this.TimelineOpt.OneWeek:
          var filteredDataOneWeek = this.filterAndSortChartData(dataSet, "week");
          this.revenuesInfo.revenuesCategories = ["Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi", "Pazar"];
          var startOfWeek = moment()
            .startOf("isoWeek")
            .toDate()
            .getDate();
          this.revenuesInfo.revenuesSeriesData = Array(7)
            .fill(0)
            .map((val, idx) => {
              let price = 0;
              filteredDataOneWeek.forEach((pValue) => {
                if (startOfWeek + idx === pValue.ConfirmedDate.getDate()) price = pValue.ConfirmedPrice;
              });
              return price;
            });
          break;
        case this.TimelineOpt.OneMonth:
          var filteredDataOneMonth = this.filterAndSortChartData(dataSet, "month");
          this.revenuesInfo.revenuesCategories = filteredDataOneMonth?.map((pValue) => pValue.ConfirmedDate.toLocaleString("tr-TR").substring(0, 10));
          this.revenuesInfo.revenuesSeriesData = filteredDataOneMonth?.map((pValue) => pValue.ConfirmedPrice);
          this.revenuesInfo.revenuesCategories.push(new Date().toLocaleString("tr-TR"));
          this.revenuesInfo.revenuesSeriesData.push(0);
          break;
        case this.TimelineOpt.OneYear:
          var filteredData = this.filterAndSortChartData(dataSet, "year");

          this.revenuesInfo.revenuesCategories = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
          this.revenuesInfo.revenuesSeriesData = Array(12)
            .fill(0)
            .map((val, idx) => {
              let price = 0;
              filteredData.forEach((pValue) => {
                if (idx === pValue.ConfirmedDate.getMonth()) price = pValue.ConfirmedPrice;
              });
              return price;
            });
          break;
        case this.TimelineOpt.All:
          var filteredDataAll = this.filterAndSortChartData(dataSet, "all");
          this.revenuesInfo.revenuesCategories = filteredDataAll?.map((pValue) => pValue.ConfirmedDate.toLocaleString("tr-TR").substring(0, 10));
          this.revenuesInfo.revenuesSeriesData = filteredDataAll?.map((pValue) => pValue.ConfirmedPrice);
          this.revenuesInfo.revenuesCategories.push(new Date().toLocaleString("tr-TR"));
          this.revenuesInfo.revenuesSeriesData.push(0);
          break;
        default:
          var filteredDataDefault = this.filterAndSortChartData(dataSet, "all");
          this.revenuesInfo.revenuesCategories = filteredDataDefault?.map((pValue) => pValue.ConfirmedDate.toLocaleString("tr-TR").substring(0, 10));
          this.revenuesInfo.revenuesSeriesData = filteredDataAll?.map((pValue) => pValue.ConfirmedPrice);
          break;
      }
    },
    setShipments(opt) {
      this.activeShipmentSeries = opt;
      var dataSet = this.dashboardStatistics.PeriodicValuesForAdverts;
      switch (opt) {
        case this.TimelineOpt.OneDay:
          var filteredDataOneDay = this.filterAndSortChartData(dataSet, "day");
          this.shipmentsInfo.shipmentsCategories = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];
          this.shipmentsInfo.shipmentsSeriesData = Array(24)
            .fill(0)
            .map((val, idx) => {
              let price = 0;
              filteredDataOneDay.forEach((pValue) => {
                if (idx === pValue.ConfirmedDate.getHours()) price = pValue.Count;
              });
              return price;
            });
          break;
        case this.TimelineOpt.OneWeek:
          var filteredDataOneWeek = this.filterAndSortChartData(dataSet, "week");
          this.shipmentsInfo.shipmentsCategories = ["Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi", "Pazar"];
          var startOfWeek = moment()
            .startOf("isoWeek")
            .toDate()
            .getDate();
          this.shipmentsInfo.shipmentsSeriesData = Array(7)
            .fill(0)
            .map((val, idx) => {
              let price = 0;
              filteredDataOneWeek.forEach((pValue) => {
                if (startOfWeek + idx === pValue.ConfirmedDate.getDate()) price = pValue.Count;
              });
              return price;
            });
          break;
        case this.TimelineOpt.OneMonth:
          var filteredDataOneMonth = this.filterAndSortChartData(dataSet, "month");
          this.shipmentsInfo.shipmentsCategories = filteredDataOneMonth?.map((pValue) => pValue.ConfirmedDate.toLocaleString("tr-TR").substring(0, 10));
          this.shipmentsInfo.shipmentsSeriesData = filteredDataOneMonth?.map((pValue) => pValue.Count);
          break;
        case this.TimelineOpt.OneYear:
          var filteredData = this.filterAndSortChartData(dataSet, "year");

          this.shipmentsInfo.shipmentsCategories = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
          this.shipmentsInfo.shipmentsSeriesData = Array(12)
            .fill(0)
            .map((val, idx) => {
              let price = 0;
              filteredData.forEach((pValue) => {
                if (idx === pValue.ConfirmedDate.getMonth()) price = pValue.Count;
              });
              return price;
            });
          break;
        case this.TimelineOpt.All:
          var filteredDataAll = this.filterAndSortChartData(dataSet, "all");
          this.shipmentsInfo.shipmentsCategories = filteredDataAll?.map((pValue) => pValue.ConfirmedDate.toLocaleString("tr-TR").substring(0, 10));
          this.shipmentsInfo.shipmentsSeriesData = filteredDataAll?.map((pValue) => pValue.Count);
          break;
        default:
          var filteredDataDefault = this.filterAndSortChartData(dataSet, "all");
          this.shipmentsInfo.shipmentsCategories = filteredDataDefault?.map((pValue) => pValue.ConfirmedDate.toLocaleString("tr-TR").substring(0, 10));
          this.shipmentsInfo.shipmentsSeriesData = filteredDataAll?.map((pValue) => pValue.Count);
          break;
      }
    },
    handleTrackLocation() {
      const interval = this.$store.getters["nativeTrackMapDialog"].locationInterval;
      clearInterval(interval);
      this.$store.commit("nativeTrackMapDialog", {
        trackMapDialog: false,
        locationInterval: null,
      });
    },
    handleWarehouseMapDialog() {
      const warehouseMapDialog = this.$store.getters["nativeWHMapDialog"].warehouseMapDialog;
      this.$store.commit("nativeWHMapDialog", {
        warehouseMapDialog: !warehouseMapDialog,
      });
    },
    handleCompanyMapDialog() {
      const companyMapDialog = this.$store.getters["nativeCMapDialog"].companyMapDialog;
      this.$store.commit("nativeCMapDialog", {
        companyMapDialog: !companyMapDialog,
      });
    },
    getLastDayOfMonth() {
      var today = new Date();
      return new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
    },
    /**
     *
     * @param {Array} dataSet
     * @param {string} options
     * @returns
     */
    filterAndSortChartData(dataSet, options = "all") {
      var startOfWeek = moment()
          .startOf("isoWeek")
          .toDate()
          .getDate(),
        endOfWeek = moment()
          .endOf("isoWeek")
          .toDate()
          .getDate(),
        today = new Date();

      return dataSet
        .map((pValue) => {
          return {
            ConfirmedPrice: pValue.ConfirmedPrice,
            Count: pValue.Count,
            ConfirmedDate: options !== "day" ? new Date(pValue.ConfirmedDate.substring(0, 10)) : new Date(pValue.ConfirmedDate),
          };
        })
        .filter(function(pValue, index, array) {
          let filterTime = true;
          if (options === "year") filterTime = pValue.ConfirmedDate.getFullYear() === today.getFullYear();
          else if (options === "month") filterTime = numOfDaysBetween(today, pValue.ConfirmedDate) <= 30;
          else if (options === "week") {
            filterTime = pValue.ConfirmedDate.getFullYear() === today.getFullYear() && pValue.ConfirmedDate.getMonth() === today.getMonth() && startOfWeek <= pValue.ConfirmedDate.getDate() && pValue.ConfirmedDate.getDate() <= endOfWeek;
          } else if (options === "day") {
            filterTime = pValue.ConfirmedDate.getFullYear() === today.getFullYear() && pValue.ConfirmedDate.getMonth() === today.getMonth() && pValue.ConfirmedDate.getDate() === today.getDate();
          }
          if (filterTime) {
            const firstIndex = array.findIndex((item) => {
              if (options === "year" || options === "all") return item.ConfirmedDate.getFullYear() === pValue.ConfirmedDate.getFullYear() && item.ConfirmedDate.getMonth() === pValue.ConfirmedDate.getMonth();
              else if (options === "month" || options === "week") return item.ConfirmedDate.getFullYear() === pValue.ConfirmedDate.getFullYear() && item.ConfirmedDate.getMonth() === pValue.ConfirmedDate.getMonth() && item.ConfirmedDate.getDate() === pValue.ConfirmedDate.getDate();
              else if (options === "day") return item.ConfirmedDate.getFullYear() === pValue.ConfirmedDate.getFullYear() && item.ConfirmedDate.getMonth() === pValue.ConfirmedDate.getMonth() && item.ConfirmedDate.getDate() === pValue.ConfirmedDate.getDate() && item.ConfirmedDate.getHours() === pValue.ConfirmedDate.getHours();
            });
            if (firstIndex === index) return true;
            else {
              array[firstIndex].ConfirmedPrice += array[index].ConfirmedPrice;
              array[firstIndex].Count += array[index].Count;
              return false;
            }
          }
          return false;
        })
        .sort((a, b) => a.ConfirmedDate - b.ConfirmedDate);
    },

    getSortedLastSixMonthAdverts() {
      const lastSixMonthAdverts = this.dashboardStatistics.lastSixMonthAdverts;

      const points = [];

      if (lastSixMonthAdverts) {
        const currentMonth = new Date().getMonth();
        sortedMonths.slice(Math.abs(currentMonth - 5), currentMonth + 1).forEach(function(key) {
          points.push(lastSixMonthAdverts[key]);
        });
      }
      return points;
    },
    getSortedLastSixMonthAdvertLabels() {
      const labels = [];

      const currentMonth = new Date().getMonth();

      sortedMonthsTr.slice(Math.abs(currentMonth - 5), currentMonth + 1).forEach(function(key) {
        labels.push(key);
      });

      return labels;
    },

    getSortedLastOneYearPoints() {
      const points = [];
      const lastOneYearPoints = this.dashboardStatistics.LastOneYearPoints;
      if (lastOneYearPoints) {
        sortedMonths.forEach(function(key) {
          points.push(Math.round(lastOneYearPoints[key] * 10) / 10);
        });
      }

      return points;
    },
    filteredDisplayedAds() {
      return this.dashboardStatistics.advertsDetail?.filter((item) => item.IsVisited).slice(0, 5);
    },
    filteredNonDisplayedAds() {
      return this.dashboardStatistics.advertsDetail?.filter((item) => !item.IsVisited)?.slice(0, 5);
    },
    handleKeydown(e) {
      if (e.key === "Backspace" || e.key === "Delete") {
        if (this.otp4 == null || this.otp4 == "") {
          this.$refs.otp3.focus();
        }

        if (this.otp3 == null || this.otp3 == "") {
          this.$refs.otp2.focus();
        }

        if (this.otp2 == null || this.otp2 == "") {
          this.$refs.otp1.focus();
        }

        if (this.otp1 == null || this.otp1 == "") {
          this.$refs.otp1.focus();
        }
      }
    },
    async verifyOtp() {
      this.otp = this.otp1 + this.otp2 + this.otp3 + this.otp4;
      this.otp = this.otp.toString().toUpperCase();

      const otpResult = await this.$store.dispatch("confirmPhoneNumber", {
        Code: this.otp,
      });

      if (otpResult) {
        this.otpDialog = false;
        await this.$store.dispatch("getUser");
      }
    },
    async telConfirm() {
      const result = await this.$store.dispatch("sendConfirmationSMS", {
        PhoneNumber: this.$phoneNumberFormatString(this.PhoneNumber),
      });
      if (result) {
        this.telConfirmDialog = false;
        this.otpDialog = true;
        this.verifyLoading = true;
      }
    },
    handleIncrement() {
      if (this.activeShipment < this.shipments.length - 1) this.activeShipment++;
      else this.activeShipment = 0;
    },
    handleDecrement() {
      if (this.activeShipment > 0) this.activeShipment--;
      else this.activeShipment = this.shipments.length - 1;
    },
  },

  watch: {
    otpDialog: function() {
      if (this.otpDialog) {
        this.$nextTick(() => {
          this.$refs.otp1.focus();
        });
      }
    },
    otp1: function() {
      if (this.otp1 != null && this.otp1.length == 1) {
        this.$refs.otp2.focus();
      } else {
        this.$refs.otp1.focus();
      }
    },
    otp2: function() {
      if (this.otp2 != null && this.otp2.length == 1) {
        this.$refs.otp3.focus();
      } else {
        this.$refs.otp1.focus();
      }
    },
    otp3: function() {
      if (this.otp3 != null && this.otp3.length == 1) {
        this.$refs.otp4.focus();
      } else {
        this.$refs.otp2.focus();
      }
    },
    otp4: function() {
      if (this.otp4 != null && this.otp4.length == 1) {
        this.verifyOtp();
      } else {
        this.$refs.otp3.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
