var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row cursor-pointer",class:{ 'w-100': _vm.advertShowType === 'dashboard' },on:{"click":() => {
      _vm.$router.push({
        name: 'ilanlar.detay',
        query: { adId: _vm.detail.ID },
      });
    }}},[_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"ad",style:(_vm.detail.IsVisited == true ? 'background-color: #A6B3C5' : null)},[_c('div',{staticClass:"adDetails"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',[_c('span',[_vm._v(" #"+_vm._s(_vm.detail.AdvertNo)+" ")])]),(_vm.detail.BidCount > 0)?_c('div',{staticClass:"text-center hasOffer"},[_c('span',{staticStyle:{"font-size":"12px"},on:{"click":function($event){_vm.advertsDialog = true}}},[_vm._v(" "+_vm._s(_vm.detail.BidCount)+" "+_vm._s(_vm.user.IsCarrier ? "Teklif Verildi" : "Teklif")+" ")])]):_vm._e(),(_vm.detail.Status === 6)?_c('div',{staticClass:"text-center passive"},[_c('span',{staticStyle:{"font-size":"12px"},on:{"click":function($event){_vm.advertsDialog = true}}},[_vm._v(" Pasif ")])]):_vm._e()]),_c('span',[_vm._v(" "+_vm._s(_vm.detail.ProductType)+" ")]),_c('span',[_c('div',{staticClass:"d-flex"},[_c('img',{staticStyle:{"margin-right":"10px","width":"20px"},attrs:{"src":require("../../assets/icons/nkicon/destination.svg"),"alt":""}}),_c('div',[_c('div',{staticClass:"d-inline"},[_c('span',[_c('b',{staticStyle:{"color":"#ffffff"}},[_vm._v("Yükleme:")]),_vm._v(" "+_vm._s(_vm.detail.WarehouseDistrict)+" | "+_vm._s(_vm.detail.WarehouseCity)+" ")])]),_vm._v("   "),_c('br'),_c('div',{staticClass:"d-inline"},[_c('span',[_c('b',{staticStyle:{"color":"#ffffff"}},[_vm._v("İndirme:")]),_vm._v(" "+_vm._s(_vm.detail.DeliveryCompanyDistrict)+" | "+_vm._s(_vm.detail.DeliveryCompanyCity))])])])])]),_c('span',[_c('img',{staticStyle:{"margin-right":"10px","width":"20px"},attrs:{"src":require("../../assets/icons/nkicon/calendar.svg"),"alt":""}}),_vm._v(_vm._s(_vm.$moment(_vm.detail ? _vm.detail.AdvertDate : null) .add(1, "days") .format("LL"))+" ")])]),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"margin-right":"20px"}},[_c('img',{staticStyle:{"cursor":"pointer","margin-right":"30px","width":"60px"},attrs:{"src":require("../../assets/icons/nkicon/adddetail.svg"),"alt":""}})])
}]

export { render, staticRenderFns }